<template>
    <div class="m-vue-pdf">
        <div id="preViewPdf"></div>
    </div>
</template>

<script>
import Pdfh5 from "pdfh5"
import "pdfh5/css/pdfh5.css"
export default {
    components: {

    },
    data() {
        return {
            loaded: false,
            pdfh5: null,
            pdfUrl: ""
        };
    },
    props:{
        
    },
    created() {
        this.pdfUrl = this.$query('url');
    },
    mounted() {
        this.loadPdf(this.pdfUrl)
    },
    methods: {
        loadPdf(url) {
            this.pdfh5 = new Pdfh5("#preViewPdf", {
                pdfurl: url
            })
        }
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.m-vue-pdf {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    overflow: hidden;
    z-index: 200;
}
</style>